<template>
  <v-container>
    <v-row
    >
      <v-col lg="1" cols="12">
        <a
            href="/"
        ><v-img
            :src="require('../assets/Bouton retour.png')"
            contain
            max-height="34"
        /></a>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          class="mb-5 mt-10"
          cols="12"
      >
        <v-row justify="center">
          <a
              href="/outils/calculator"
              class="subheading mx-3 roue1"
          >
            <v-img
                :src="require('../assets/outils/calculateur-qt@2x.png')"
                class="my-3"
                contain
                max-height="110"
                width="230"
            />
          </a>
          <a
              href="https://www.pipedrive.com/fr"
              class="subheading mx-3 roue2"
              target="_blank"
          >
            <v-img
                :src="require('../assets/outils/Pipedrive@2x.png')"
                class="my-3 mt-n6"
                contain
                max-height="110"
                width="200"
            />
          </a>
          <a
              href="https://quickbooks.intuit.com/fr/"
              class="subheading mx-3 roue3"
              target="_blank"
          >
            <v-img
                :src="require('../assets/outils/Quickbooks@2x.png')"
                class="my-3 mr-6"
                contain
                max-height="110"
                width="200"
            />
          </a>
        </v-row>
        <v-row justify="center">
          <a
              href="/outils/devis"
              class="subheading mx-3 roue4"
          >
            <v-img
                :src="require('../assets/outils/Calculateur@2x.png')"
                class="my-3"
                contain
                max-height="110"
                width="200"
            />
          </a>
          <a
              href="/outils"
              class="subheading mx-3 roue5"
          >
            <v-img
                :src="require('../assets/outils/boite-a-outils@2x-100.jpg')"
                class="my-3"
                contain
                max-height="390"
                width="300"
            />
          </a>
          <a
              href="https://www.google.com/intl/fr/gmail/about/"
              class="subheading mx-3 roue6"
              target="_blank"
          >
            <v-img
                :src="require('../assets/outils/Mail@2x.png')"
                class="my-3 ml-7"
                contain
                max-height="110"
                width="230"
            />
          </a>
        </v-row>
        <v-row justify="center" >
          <a
              href="/catalogue"
              class="subheading mx-3 roue7"
          >
            <v-img
                :src="require('../assets/outils/Annuaire@2x.png')"
                class="my-3"
                contain
                max-height="110"
                width="200"
            />
          </a>
          <a
              href="https://www.google.com/intl/fr/calendar/about/"
              class="subheading mx-3 roue8"
              target="_blank"
          >
            <v-img
                :src="require('../assets/outils/Agenda@2x.png')"
                class="my-3"
                contain
                max-height="110"
                width="230"
            />
          </a>
        </v-row>
        <v-row justify="center">
          <a
              href="https://www.fiveaday.fr/"
              class="subheading mx-3 roue9"
              target="_blank"
          >
            <v-img
                :src="require('../assets/outils/Site@2x.png')"
                class="my-3"
                contain
                max-height="110"
                width="230"
            />
          </a>
          <a
              href="https://www.linkedin.com/"
              class="subheading mx-3 roue10"
              target="_blank"
          >
            <v-img
                :src="require('../assets/outils/Linkedin@2x.png')"
                class="my-3 mt-10"
                contain
                max-height="110"
                width="200"
            />
          </a>
          <a
              href="https://calendly.com/"
              class="subheading mx-3 roue11"
              target="_blank"
          >
            <v-img
                :src="require('../assets/outils/Calendly@2x.png')"
                class="my-3"
                contain
                max-height="110"
                width="200"
            />
          </a>
        </v-row>

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>
@media (min-width: 1250px) {
  .roue7{
    position: absolute;
    left: 15%;
    top: 54%;
  }
  .roue8{
    position: absolute;
    left: 63%;
    top: 54%;
  }
}
@media screen and (min-width: 200px) and (max-width: 540px) {
  .roue1{
  }
  .roue2{
  }
  .roue3{

  }
  .roue4{

  }
  .roue5{

  }
  .roue6{

  }
  .roue7{

  }
  .roue8{

  }
  .roue9{

  }
  .roue10{

  }
  .roue11{

  }
}
</style>